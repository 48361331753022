import React, { useEffect, useState } from 'react'

const SuccessHandler = () => {
  const redirect = window.sessionStorage.getItem('callbackurl')

  // window.sessionStorage.removeItem('token')

  if (redirect) {
    setTimeout(() => {
      window.sessionStorage.removeItem('callbackurl')
      window.location.href = `${redirect}`
    }, 3000)
  }

  return (
    <>
      <h1>
        <span>Pedido </span>validado! #9928
      </h1>
      <hr />
      <p className="text-center">
       Parabéns! você agora já é don@ de um produto supimpa! :) <br/><br/>
       Abra a caixa, e desfrute!<br/><br/><br/><br/>
      </p>
    </>
  )
}

export default SuccessHandler
