import React, { useEffect, useState } from 'react'

import './LoadingHandler.sass'

const LoadingHandler = () => {

  useEffect(() => {
    const check = sessionStorage.getItem('token')
    const finishHim = window.sessionStorage.getItem('finish')

    if(finishHim){
      console.log('else')
    }else 
    if(check && check === 'vrim'){
      sessionStorage.setItem('finish', 'true')
      window.location.reload()
    }
  }, [])


  return (
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default LoadingHandler
