import React, { Component, useCallback, useEffect, useState } from 'react'
import './OnboardCard.sass'
import bothLogo from './../images/logo_bepay.png'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'
import LoadingHandler from './StatesComponents/LoadingHandler'

interface Props {
  onResponse: (response: any) => void
}

declare global {
  interface Window {
    constraints: any
    stream: any
  }
}

const constraints = (window.constraints = {
  video: true
})

function HomeLoading({ onResponse }: Props) {
  const [photoState, setphotoState] = useState<string>('')

  const [mironga, setMironga] = useState(false)

  const handleResponse = useCallback((message: string) => {
    setphotoState(message)
  }, [])

  useEffect(() => {
    setTimeout(() => {
        setMironga(true)
    }, 3000);

    setTimeout(() => {
        console.log('welcome to the mato')
        onResponse({
            step: 'signup'
          })
    }, 4000);
  }, [])

  const handleSuccess = (stream: any) => {
    // setUserHasCamera(true)
    // const videoTracks = stream.getVideoTracks()
  }

    return(
    <div className={`main-loading ${mironga ?'active': ''}`}>
        <div className="logos">
          <img src={bothLogo} className="logo" alt="BePass" />
        </div>
      <LoadingHandler />
    </div>
  )
}

export default HomeLoading
