import React, { useCallback, useEffect, useState } from 'react'
import bepassCopyright from './images/bepass_logo.png'
import bepassLogo from './images/bepass_logo.png'
import bothLogo from './images/logo_bepay.png'
import caneca from './images/caneca.jpg'
import './App.sass'

import warningIcon from './images/warning-icon.svg'
import { BASE } from './basedata/constants'

import FaceOnboard from './Biometric/FaceOnboard'
import FaceInstructions from './Biometric/FaceInstructions'
import UserSignup from './User/UserSignup'
import Welcome from './Welcome'
import SuccessHandler from './Biometric/StatesComponents/SuccessHandler'
import { Card, Table } from 'react-bootstrap'
import LoadingHandler from './Biometric/StatesComponents/LoadingHandler'
import HomeLoading from './Biometric/homeLoading'
import Cart from './Biometric/Cart'

function App() {

  const [userState, setuserState] = useState<string>('')
  const [debugMan, setDebugMan] = useState(false)

  const [currentStep, setCurrentStep] = useState('waiting')

  const [userToken, setuserToken] = useState<any>('')

  const [logToken, setlogToken] = useState<any>('')
  const [logStep, setlogStep] = useState<any>('')
  const [logResponse, setlogResponse] = useState<any>('')
  const [logRequest, setlogRequest] = useState<any>('')


  const handleResponse = useCallback((message: any) => {
    setuserState(message.step)
    if (message.token) {
      setuserToken(message.token)
    }
  }, [])

  const getTransactionStatus = () => {

    const params = new URLSearchParams(window.location.search)
    const transactionId = params.get('token')

    setlogToken(transactionId)
    setlogResponse('1.na')
    setlogStep('antes de match')
    setlogRequest(JSON.stringify({transaction_id: transactionId}))
    
    fetch(`${BASE.biometric_service.base_url}${BASE.biometric_service.find_face}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'SyDu_QN9N9UxDXEoH8sNiPXcix4E4dft3',
        'x-tenant-id': 'user-core-hml-xmpcc',
        Authorization: BASE.api.token
      },
      body: JSON.stringify({
        transaction_id: transactionId
      })
    })
    .then((res) => res.json())
    .then((response) => {

      setlogStep('retorno de match')
      setlogResponse(JSON.stringify(response))

      if(response.match){
          setTransactionStatus(response)
      }else{
        if(response.message ===  'rematch'){
          const documentToMatch = response.top.map((item:any) => item.user_ref.replace(/\D+/g, ""))
          sessionStorage.setItem('toproll', JSON.stringify(documentToMatch))
          return setuserState('rematch')
        }
        setCurrentStep('not-found')
        setTimeout(() => {
          setTransactionStatus(response)
        }, 3000);
      }
    })
    .catch((error) => {

      setlogStep('errro em dar o match')
      setlogResponse(JSON.stringify(error))

      setTimeout(() => {
        // alert('errou')
        getTransactionStatus()
      }, 5000);
    })
  }

  const setTransactionStatus = (data:any) => {

    setlogStep('antes de setar no fb')
    setlogResponse('2.na')
    setlogRequest(JSON.stringify({
      status: data.match ? 'found':'notFound',
      document: data.user?.replace(/\D+/g, "")
  }))

    const params = new URLSearchParams(window.location.search)

    const transactionId = params.get('token')

  setTimeout(() => {
    fetch(`${BASE.company_service.base_url}${BASE.company_service.setFaceStatus}/${transactionId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-tenant-id': 'user-core-hml-xmpcc',
        'x-api-key': 'SyDu_QN9N9UxDXEoH8sNiPXcix4E4dft3',
        Authorization: BASE.api.token
      },
      body: JSON.stringify({
        status: data.match ? 'found':'notFound',
        document: data.user?.replace(/\D+/g, "")
    })
    })    .then((res) => res.json())
    .then((response) => {

      setlogStep('retorno pos setar fb')
      setlogResponse(JSON.stringify(response))

    })
    .catch((error) => {
      setlogStep('errro em setar db')
      setlogResponse(JSON.stringify(error))
      setTimeout(() => {
        setTransactionStatus(data)
      }, 5000);
    })
  }, 4000);
  }

  let foi = 0
  useEffect(() => { 

    if(foi === 0){
      foi++
    const finishHim = window.sessionStorage.getItem('finish')

    if(finishHim && finishHim.length > 1){
      setuserState('facesuccess')
    }else{
      setTimeout(() => {
        getTransactionStatus()
      }, 3000);
    }
  }

  }, [])
  return (
    <> 
    {currentStep === 'waiting' && (
    <div className="loading-handler-wrapper">
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <span>Processando...</span>
    </div>
    )}

{currentStep === 'not-found' && (
    <div className="loading-handler-wrapper">
    <img src={warningIcon} />
    <span>Face não encontrada!</span>
    <span className="caption">Desculpe, não conseguimos localizar sua face. <br /> Estamos te retornando para selecionar outra opção de pagamento.</span>
    </div>
    )}
    {debugMan && (
      <>
          <h2>v.1.4</h2>
    <p>log: {logToken}</p>
    <p>step: {logStep}</p>
    <p>request: {logRequest}</p>
    <p>response: {logResponse}</p>
    </>
    )}

        {userState === 'faceinformation' ? (
          <FaceInstructions onResponse={handleResponse} />
        ) : userState === 'facelogin' ? (
          <FaceOnboard token={userToken} />
        ) : userState === 'welcome' ? (

          <div className="Container main-loading zedobigode active">
            <div className="logos">
              <img src={bothLogo} className="logo" alt="BePass" />
            </div>
            <FaceOnboard token={userToken} />

            <p className="copyright">
            powered by{' '}
            <a href="http://bepass.com.br" rel="noreferrer" target="_blank">
              <img src={bepassCopyright} className="logo-bepass" alt="bepass" />
            </a>
          </p>
          </div>

          
        ) : userState === 'signup' ? (
          <div className="Container main-loading zedobigode active">
            <div className="logos">
              <img src={bothLogo} className="logo" alt="BePass" />
            </div>
            <UserSignup onResponse={handleResponse} />

            <p className="copyright">
            powered by{' '}
            <a href="http://bepass.com.br" rel="noreferrer" target="_blank">
              <img src={bepassCopyright} className="logo-bepass" alt="bepass" />
            </a>
          </p>
          </div>
        ) : userState === 'facesuccess' ? (

          <div className="Container main-loading zedobigode active">
            <div className="logos">
              <img src={bothLogo} className="logo" alt="BePass" />
            </div>
            <Card>
              <SuccessHandler />
            </Card>

            <p className="copyright">
            powered by{' '}
            <a href="http://bepass.com.br" rel="noreferrer" target="_blank">
              <img src={bepassCopyright} className="logo-bepass" alt="bepass" />
            </a>
          </p>
          </div>

        )  : userState === 'rematch' ? (
          <UserSignup onResponse={handleResponse} />
        ) : userState === 'ss' ? (
          <>
          <HomeLoading  onResponse={handleResponse}/>
          <p className="copyright">
          powered by{' '}
          <a href="http://bepass.com.br" rel="noreferrer" target="_blank">
            <img src={bepassCopyright} className="logo-bepass" alt="bepass" />
          </a>
        </p>
        </>
          // <Welcome onResponse={handleResponse} />
        ) : userState === 'cart' ? (
          <div className="cart Container main-loading zedobigode active">
            <div className="logos">
              <img src={bothLogo} className="logo" alt="BePass" />
            </div>
            <Cart onResponse={handleResponse} />

            <p className="copyright">
            powered by{' '}
            <a href="http://bepass.com.br" rel="noreferrer" target="_blank">
              <img src={bepassCopyright} className="logo-bepass" alt="bepass" />
            </a>
          </p>
          </div>
          // <Welcome onResponse={handleResponse} />
        ) :<></>}
              <div id="grid"></div>
    </>
  )
}

export default App
