import React, { Component, useCallback, useEffect, useState } from 'react'
import './UserSignup.sass'
import { BASE } from './../basedata/constants'

import bepassCopyright from './../images/bepass_logo.png'
import bepassLogo from './../images/bepass_logo.png'
import bothLogo from './../images/logo_bepay.png'
import caneca from './../images/caneca.jpg'
import { Card, Table } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import LoadingHandler from '../Biometric/StatesComponents/LoadingHandler'

interface Props {
  onResponse: (response: any) => void
}

const maskCPF = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

function UserSignup({ onResponse }: Props) {

  const [currentStep, setCurrentStep] = useState(1)

  const [accessCode, setAccessCode] = useState('')

  const [registerError, setregisterError] = useState({
    status: false,
    message: ''
  })

  const [formData, setformData] = useState({
    fullname: '',
    externalId: ''
  })


    const {
      register,
      handleSubmit,
      setError,
      formState: { errors },
      clearErrors
    } = useForm()

    const onSubmit = (data: any) => {
      
      const currentCpf = data.document.replace(/^\s+|\s+$/g, '').replace(/\./g, '').replace(/-/g, '')

        const documentToMatch = JSON.parse(sessionStorage.getItem('toproll') || '')

        // @ least one field
        if (!currentCpf) {
          setError('neitherHasSome', {
            type: 'manual',
            message: 'Você precisa preencher com seu documento'
          })
          return
        }else if (currentCpf && documentToMatch.indexOf(currentCpf) < 0) {
          return setTransactionStatus({
            match: false,
          })
        }
        
        return setTransactionStatus({
          match: true,
          user: currentCpf
        })
    }
    

    const setTransactionStatus = (data:any) => {

      const params = new URLSearchParams(window.location.search)
  
      const transactionId = params.get('token')
  
setTimeout(() => {
  fetch(`${BASE.company_service.base_url}${BASE.company_service.setFaceStatus}/${transactionId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'SyDu_QN9N9UxDXEoH8sNiPXcix4E4dft3',
      'x-tenant-id': 'user-core-hml-xmpcc',
      Authorization: BASE.api.token
    },
    body: JSON.stringify({
      status: data.match ? 'found':'notFound',
      document: '35325926810' // TODO - Mudar para o deschumbado~
  })
  })
}, 4000);

      onResponse('loading')
    }

    const [trilonga, setTrilonga] = useState(false)

    setTimeout(() => {
      setTrilonga(true)
  }, 1000);

    return (
      <>
        <form className={` ${trilonga ? 'active': ''}`} onSubmit={handleSubmit(onSubmit)}>
          <h1>
            Poxa <span>:(</span>
          </h1>
          <p>
            Não conseguimos identificar você de primeira. Você pode informar seu cpf para tentarmos novamente?
          </p>
          <div className="form-wrapper">
            <div className="form-floating">
              <input
                placeholder="CPF"
                className="form-control form-control-lg"
                {...register('document')}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskCPF(value)
                }}
              />
              <label className="form-label" htmlFor="document">
                CPF
              </label>
              {errors.neitherHasSome && (
                <span className="label-error">
                  Você precisa preencher um CPF válido
                </span>
              )}
              {registerError.status && (
                <span className="label-error">{registerError.message}</span>
              )}
            </div>
          </div>
          <div className="form-group form-submit">
            <input
              className="btn btn-primary form-control form-control-lg"
              type="submit"
              value="Próximo"
              onClick={() => clearErrors()}
            />
          </div>
          {/* <a className="help-link" href="#">
          Ajuda
        </a> */}
        </form>
      </>
    )
}

export default UserSignup
