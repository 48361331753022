import React, { Component, useCallback, useEffect, useState } from 'react'
import './OnboardCard.sass'


import bepassCopyright from './../images/bepass_logo.png'
import bepassLogo from './../images/bepass_logo.png'
import bothLogo from './../images/logo_bepay.png'
import caneca from './../images/caneca.jpg'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'
import { Card, Table } from 'react-bootstrap'

interface Props {
  onResponse: (response: any) => void
}

declare global {
  interface Window {
    constraints: any
    stream: any
  }
}

const constraints = (window.constraints = {
  video: true
})

function Cart({ onResponse }: Props) {
  const [photoState, setphotoState] = useState<string>('')

  const [userHasCamera, setUserHasCamera] = useState(true)

  const handleResponse = useCallback((message: string) => {
    setphotoState(message)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // id=123
    if (params.get('fixBrowser')) {
      // setphotoState('mobile')
    }
  }, [])

  const handleSuccess = (stream: any) => {
    setUserHasCamera(true)
    const videoTracks = stream.getVideoTracks()
  }

  return (
    <Card>
    <Card.Body>
    <Table>
    <thead>
      <tr className='col-header'>
        <th className="text-left" colSpan={3}>Produto</th>
        <th>Qnt.</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr className='col-body'>
        <td className='product-image'><img src={caneca} /></td>
        <td>Caneca BePay</td>
        <td colSpan={2}>1</td>
        <td>R$ 40,00</td>
      </tr>
    </tbody>

    <tfoot>
      <tr className='col-footer'>
        <td  className="text-left" colSpan={4}>Total</td>
        <td>R$ 40,00</td>
      </tr>
    </tfoot>
    </Table>

    <div className="form-group form-submit">
      <input
        className="btn btn-primary form-control form-control-lg"
        type="submit"
        value="Próximo"
        onClick={() => onResponse({
          token: 'currentToken',
          step: 'faceproceed'
        })}
      />
    </div>
    </Card.Body>
  </Card>
  )
}

export default Cart
